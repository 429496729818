




































































































import { Component, Vue } from 'vue-property-decorator';
import MainLogo from '@/layout/components/logo.vue';

import { otherModule } from '@/store/modules/other';

import { ArticleGetList, ArticleId } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'Footer',
    components: {
        MainLogo
    }
})
export default class Footer extends Vue {

    public menu: any = [
        [
            { label: this.$t('footer.menu.list1.menu1'), url: '' },
            { label: this.$t('footer.menu.list1.menu2'), url: 'Introduce' },
            { label: this.$t('footer.menu.list1.menu3'), url: 'Teaching' },
            { label: this.$t('footer.menu.list1.menu4'), url: 'Route' },
            { label: this.$t('footer.menu.list1.menu5'), url: 'Terms' }
        ],
        [
            { label: this.$t('footer.menu.list2.menu1'), url: '' },
            { label: this.$t('footer.menu.list2.menu2'), url: 'Home' },
            { label: this.$t('footer.menu.list2.menu3'), url: 'Standard' },
            { label: this.$t('footer.menu.list2.menu4'), url: '' },
            { label: this.$t('footer.menu.list2.menu5'), url: 'category' }
        ],
        [
            { label: this.$t('footer.menu.list3.menu1'), url: '' },
            { label: this.$t('footer.menu.list3.menu2'), url: 'EnterpriseOverview' },
            { label: this.$t('footer.menu.list3.menu3'), url: 'BusinessConsulting' },
            { label: this.$t('footer.menu.list3.menu4'), url: 'EnterpriseStyle' },
            { label: this.$t('footer.menu.list3.menu5'), url: 'BusinessCooperation' }
        ],
        [
            { label: this.$t('footer.menu.list4.menu1'), url: '' },
            { label: this.$t('footer.menu.list4.menu2'), url: '/login/signin' },
            { label: this.$t('footer.menu.list4.menu3'), url: '/login/register' },
            { label: this.$t('footer.menu.list4.menu4'), url: 'ReturnGoods' },
            { label: this.$t('footer.menu.list4.menu5'), url: 'policy' }
        ]
    ];

    public menuOther: any = [
        { label: this.$t('footer.friendship.menu1'), url: 'https://www.taobao.com/' },
        { label: this.$t('footer.friendship.menu2'), url: 'https://www.1688.com/' },
        { label: this.$t('footer.friendship.menu3'), url: 'https://www.tmall.com/' },
        { label: this.$t('footer.friendship.menu4'), url: 'https://www.jd.com/' },
        { label: this.$t('footer.friendship.menu5'), url: 'https://www.suning.com/' },
        { label: this.$t('footer.friendship.menu6'), url: 'https://www.amazon.cn/' }

    ];

    private created() {
        // 如果等于泽宝
        // eslint-disable-next-line eqeqeq
        if (this.shopId == '7') {
            this.menu[0][3] = { label: this.$t('menu.menu4-5'), url: 'SelfPromotion' };
        }

        this.init();
        this.init2();
    }

    // 小菜單跳轉
    public jump(item) {
        if (item.url === 'policy') {
            this.agreementJump('隐私保护声明');
            return;
        }

        if (item.url === 'category') {
            this.agreementJump('违禁品说明');
            return;
        }

        if (item.url.indexOf('login') !== -1) {
            this.$router.push({
                path: item.url
            });
            return;
        }

        if (item.url === '') {
            return;
        }
        this.$router.push({
            name: item.url
        });
    }

    // 协议跳转
    public agreementJump(type) {
        this.$router.push({
            path: `/agreement/agreement/${type}`
        });
    }

    public open(url) {
        window.open(url);
    }

    // 这里处理查询的

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 4,
        Expressionable: []
    };

    private async init() {
        try {
            // 这里的 FieldValue 值，根据总后台管理，写死
            this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 34, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                this.draw(DataList[0]);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async init2() {
        try {
            // 这里的 FieldValue 值，根据总后台管理，写死
            this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 35, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                this.draw2(DataList[0]);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async draw(row) {
        try {
            const { Data } = await ArticleId(row.ArticleId);
            const article: any = document.getElementById('articlea');
            article.innerHTML = '';
            article.appendChild(this.parseDom(Data.ArticleBody));
        } catch (error) {
            console.warn(error);
        }
    }

    private async draw2(row) {
        try {
            const { Data } = await ArticleId(row.ArticleId);
            const article: any = document.getElementById('article2');
            article.innerHTML = '';
            article.appendChild(this.parseDom(Data.ArticleBody));
        } catch (error) {
            console.warn(error);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }

    public get shopConfig() {
        return otherModule.shopConfig;
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }

    public get shopName() {
        return appConfigModule.appConfig.vueShopName;
    }
}
